'use strict';

angular.module('app.users', []).service('UsersService', ['$http', 'CONFIG', function ($http, CONFIG) {
  this.url = CONFIG.API_URL + 'users/';
  this.currentUser = null;

  this.getAll = function (callback) {
    return $http.get(this.url).then(callback);
  };

  this.getUser = function (id, callback) {
    return $http.get(this.url + id + '/').then(callback);
  };

  this.setCurrentUser = function (user) {
    this.currentUser = user;
  };

  this.getCurrentUser = function () {
    return this.currentUser;
  };
}]);
'use strict';

angular.module('app.users').controller('UsersCtrl', ['$scope', 'UsersService', '$location', function ($scope, UsersService, $location) {

  $scope.users = [];

  UsersService.getAll(function (response) {
    $scope.users = response.data.results;
  });

  $scope.userDetail = function (user) {
    UsersService.setCurrentUser(user);
    $location.path('/usuarios/' + user.id);
  };
}]);
'use strict';

angular.module('app.users').controller('UserDetailCtrl', ['$scope', 'UsersService', '$routeParams', function ($scope, UsersService, $routeParams) {

  $scope.user = UsersService.getCurrentUser();

  if (!$scope.user) {
    UsersService.getUser($routeParams.id, function (response) {
      $scope.user = response.data;
    });
  }
}]);
'use strict';

angular.module('app.users').controller('ProfileCtrl', ['$scope', 'UsersService', function ($scope, UsersService) {

  console.info("profile Ctrl works");
}]);
'use strict';

angular.module('app.home', []).service('HomeService', ['$http', 'CONFIG', function ($http, CONFIG) {
  var url = CONFIG.API_URL + 'home/';

  this.handleError = function (res) {
    console.warn(res);
  };

  this.search = function (s_operation, callback, error, noblock) {
    return $http.get(url + 'search-operation/' + s_operation).then(callback, error);
  };
  this.create = function (s_operation, callback, error, noblock) {
    console.log(s_operation);
    return $http.post(url + 'create-operation', s_operation).then(callback, error);
  };
}]);
'use strict';

angular.module('app.home').controller('MainCtrl', ['$scope', '$rootScope', 'AUTH_EVENTS', 'Session', function ($scope, $rootScope, AUTH_EVENTS, Session) {

  $scope.user = null;
  $scope.expandedMenu = true;

  console.log("%cDesarrollado por TRASMOVIL S.A.S ", "; color: red; font-size: x-large");
  console.log("%c:D", "; color: red; font-size: x-large");
  $scope.setCurrentUser = function (user) {
    $scope.user = user;
  };

  $scope.logout = function (user) {
    $rootScope.$broadcast(AUTH_EVENTS.logout);
  };
  var screenSizes = $.AdminLTE.options.screenSizes;

  var miniSidebarHandler = function miniSidebarHandler() {
    //Enable hide menu when clicking on the content-wrapper on small screens
    if ($(window).width() <= screenSizes.sm - 1 && $("body").hasClass("sidebar-open")) {
      $("body").removeClass('sidebar-open');
    }
  };

  $(".content-wrapper").click(miniSidebarHandler);

  $scope.toggleMenu = function () {
    console.warn('abrir');
    //Enable sidebar push menu
    if ($(window).width() > screenSizes.sm - 1) {
      if ($("body").hasClass('sidebar-collapse')) {
        $("body").removeClass('sidebar-collapse').trigger('expanded.pushMenu');
      } else {
        $("body").addClass('sidebar-collapse').trigger('collapsed.pushMenu');
      }
    }
    //Handle sidebar push menu for small screens
    else {
        if ($("body").hasClass('sidebar-open')) {
          $("body").removeClass('sidebar-open').removeClass('sidebar-collapse').trigger('collapsed.pushMenu');
        } else {
          $("body").addClass('sidebar-open').trigger('expanded.pushMenu');
        }
      }
  }; //end toggle
  $rootScope.$on('toggleMenu', function () {
    miniSidebarHandler();
  });

  //init
  $scope.setCurrentUser(Session.getUser());
}]);
'use strict';

angular.module('app.home').controller('HomeCtrl', ['$scope', 'Notification', 'HomeService', function ($scope, Notification, HomeService) {

	$scope.open_form_create = function () {
		console.log('abrir');
		$('#createFormModal').modal('show');
	};

	$("#id_of_textbox_operations").keyup(function (event) {
		if (event.keyCode == 13) {
			$("#id_of_button_operations").click();
		}
	});

	$scope.search_operation = function (s_operation) {

		console.log(s_operation);

		HomeService.search(s_operation, function (response) {
			$scope.operations = response.data;
		});
	};
	$scope.openFormDetailOperation = function (detail_operation) {
		$scope.operation = detail_operation;

		$('#detailFormModal').modal('show');
	};

	$scope.create_operation = function (s_operation) {
		s_operation.periodo_inicio = s_operation.periodo_inicio.getFullYear() + '-' + ('0' + (s_operation.periodo_inicio.getMonth() + 1)).slice(-2) + '-' + ('0' + s_operation.periodo_inicio.getDate()).slice(-2);
		s_operation.periodo_fin = s_operation.periodo_fin.getFullYear() + '-' + ('0' + (s_operation.periodo_fin.getMonth() + 1)).slice(-2) + '-' + ('0' + s_operation.periodo_fin.getDate()).slice(-2);
		// console.log(s_operation);
		// return;
		HomeService.create(s_operation, function (response) {

			$scope.operation = {};
			$('#createFormModal').modal('hide');
			Notification.success('Operacion creada exitosamente');
		});
	};
}]);
'use strict';

angular.module('app.auth', []).service('Session', [function () {

  this.token = null;
  this.user = null;

  //create session from local storage
  this._createLc = function (token, user) {
    window.localStorage.setItem('token', angular.toJson(token));
    window.localStorage.setItem('user', angular.toJson(user));
  };

  //destroy session from local storage 
  this._destroyLc = function () {
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('user');
  };

  //create session from memory 
  this.create = function (token, user) {
    this.token = token;
    this.user = user;
    this._createLc(token, user);
  };

  //destroy session from memory 
  this.destroy = function () {
    this.token = null;
    this.user = null;
    this._destroyLc();
  };

  //get token from memory or localStorage
  this.getToken = function () {
    var token = this.token;
    if (!token) {
      token = angular.fromJson(window.localStorage.getItem('token'));
    }
    return token;
  };

  //get user from memory or localStorage
  this.getUser = function () {
    var user = this.user;
    if (!user) {
      user = angular.fromJson(window.localStorage.getItem('user'));
    }
    return user;
  };

  this.isAuthenticated = function () {
    return this.getToken() != null;
  };
}]);
'use strict';

angular.module('app.auth').factory('AuthInterceptor', ['$rootScope', '$q', 'AUTH_EVENTS', 'Session', function ($rootScope, $q, AUTH_EVENTS, Session) {
  return {
    // poner el token en cada request
    request: function request(config) {
      var token = Session.getToken();
      if (token) {
        config.headers.Authorization = 'Token ' + token;
      }
      return config;
    },
    responseError: function responseError(response) {
      console.log("entro por interceptor de error");
      $rootScope.$broadcast({
        401: AUTH_EVENTS.notAuthenticated,
        403: AUTH_EVENTS.notAuthorized
      }[response.status], response);
      return $q.reject(response);
    }
  };
}]);
'use strict';

angular.module('app.auth').service('LoginService', ['$http', 'CONFIG', 'Session', function ($http, CONFIG, Session) {
  var url = CONFIG.API_URL + 'api-token-auth/';

  this.handleError = function (res) {
    console.warn(res);
  };

  /*
   *api token request
   */
  this.requestToken = function (data, callback, error) {
    return $http.post(url, data).then(callback, error);
  };

  /*
   *login, create a session
   */
  this.login = function (token, callback) {
    var config = {
      method: 'GET',
      url: CONFIG.API_URL + 'users/me/',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
        'Authorization': 'Bearer ' + token
      }
    };

    return $http(config).then(function (response) {
      Session.create(token, response.data);
      callback(response);
    }, this.handleError);
  };
}]);
'use strict';

angular.module('app.auth').controller('LoginCtrl', ['$scope', 'LoginService', '$location', function ($scope, LoginService, $location) {

  $scope.user = {};
  $scope.busy = false;

  /* handle success login */

  var successLogin = function successLogin(response) {
    //get user data, create a session and redirect to home
    LoginService.login(response.data.token, function (response) {
      console.log(response);
      $scope.setCurrentUser(response.data);
      $scope.busy = false;
      $location.path('/');
    });
  };

  /* handle errors on login */
  var errorLogin = function errorLogin(response) {
    $scope.busy = false;
    // $scope.errors = response.data.non_field_errors;

    $scope.errors = [response.data.error];
    console.log(response);
  };

  /* fired when the user click to "Entrar" */
  $scope.login = function () {
    console.log($scope.user);
    $scope.busy = true;
    LoginService.requestToken($scope.user, successLogin, errorLogin);
  };
}]);
'use strict';

angular.module('app', ['ngRoute', 'templates', 'angular-loading-bar', 'xeditable', 'ui-notification', 'app.auth', 'app.home', 'app.users']);
'use strict';

angular.module('app').run(['$rootScope', 'AUTH_EVENTS', 'Session', '$location', 'editableOptions', 'editableThemes', function ($rootScope, AUTH_EVENTS, Session, $location, editableOptions, editableThemes) {
  editableOptions.theme = 'bs3'; // bootstrap3 theme. Can be also 'bs2', 'default'
  editableThemes['bs3'].submitTpl = '<button class="btn btn-flat btn-primary" type="submit">' + '<i class="fa fa-check"></i></button>';

  editableThemes['bs3'].cancelTpl = '<button class="btn btn-flat btn-default" ng-click="$form.$cancel()">' + '<i class="fa fa-times"></i></button>';
  function logout() {
    Session.destroy();
    $location.path('/login');
  }

  $rootScope.$on(AUTH_EVENTS.notAuthenticated, function () {
    logout();
    console.info('se activo interceptor not authenticated');
  });

  $rootScope.$on(AUTH_EVENTS.notAuthorized, function () {
    logout();
    console.info('se activo interceptor not authorized');
  });

  $rootScope.$on(AUTH_EVENTS.logout, function () {
    logout();
    console.info('se cerrara la sesion');
  });

  $rootScope.$on('$routeChangeStart', function (event, next) {
    if ($location.path() !== '/login') {
      if (!Session.isAuthenticated()) {
        logout();
      }
    }
  });

  $rootScope.goTo = function (url) {
    $location.path(url);
  };
}]);
'use strict';

/*
 * url routes
 */

angular.module('app').config(['$routeProvider', '$locationProvider', function ($routeProvider, $locationProvider) {
  'use strict';

  $routeProvider.when("/login", {
    controller: "LoginCtrl",
    templateUrl: "app/auth/login.html"
  }).when("/", {
    controller: "HomeCtrl",
    templateUrl: "app/home/home.html"
  }).when("/usuarios", {
    controller: "UsersCtrl",
    templateUrl: "app/users/users.html"
  }).when("/usuarios/:id", {
    controller: "UserDetailCtrl",
    templateUrl: "app/users/users-detail.html"
  }).when("/perfil", {
    controller: "ProfileCtrl",
    templateUrl: "app/users/profile.html"
  }).otherwise({
    redirectTo: '/'
  });
  $locationProvider.html5Mode(true);
}]);
"use strict";

angular.module("app").constant("CONFIG", {
  ROOT_UR: "http://localhost:8080/",
  API_URL: "laravelsensacion.coviayturs.com/api/v1/index.php/"
}).constant("AUTH_EVENTS", {
  notAuthenticated: "auth-not-authenticated",
  notAuthorized: "auth-not-authorized",
  logout: "auth-logout"
});
'use strict';

angular.module('app').config(['$httpProvider', function ($httpProvider) {
    $httpProvider.interceptors.push(['$injector', function ($injector) {
        return $injector.get('AuthInterceptor');
    }]);
}]);